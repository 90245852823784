
import { ref, nextTick, onMounted, PropType, onUnmounted, watch, defineComponent } from 'vue';
import * as echarts from 'echarts';

export interface ChartsSource {
  data: any[];
  color?: string;
  name: string;
}

export interface PieSource {
  name: string;
  value: string | number;
}

export default defineComponent({
  props: {
    // x 轴相关数据
    x: {
      type: Array as PropType<any[]>,
      default: () => [],
    },
    source: {
      type: Array as PropType<ChartsSource[] | PieSource[]>,
      default: () => [{ name: '', data: [1] }],
    },
    legend: {
      type: Object as PropType<any>,
    },
    grid: Object as PropType<any>,
    // 鼠标移入时显示的提示信息
    tooltipFormatter: {
      /* eslint-disable-next-line no-unused-vars */
      type: Function as PropType<(param: any[]) => string>,
    },
    type: {
      type: String as PropType<'line' | 'pie'>,
      default: 'line',
    },
    showxAxis: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
    onlyInteger: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  setup(props) {
    //修复图表无法根据数据变化重新生成的问题
    watch(
      () => props.source,
      () => {
        initCharts();
      },
    );

    //* 初始化 charts
    const chartsWrap = ref<HTMLDivElement>();
    let chartsInstance: echarts.ECharts;
    const initCharts = () => {
      let series: any;

      switch (props.type) {
        case 'line':
          series = props.source.map(item => {
            return {
              name: item.name,
              type: 'line',
              symbol: 'circle', //拐点样式
              symbolSize: 1,
              data: item.data,
              lineStyle: {
                width: 1,
                color: item.color,
              },
            };
          });
          break;
        case 'pie':
          series = {
            name: '',
            type: 'pie',
            radius: '70%',
            data: props.source,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
              },
            },
            emptyCircleStyle: {
              color: '#5470c6',
            },
          };
      }

      chartsInstance = echarts.init(chartsWrap.value as HTMLDivElement);
      chartsInstance.setOption({
        tooltip: {
          trigger: props.type === 'line' ? 'axis' : 'item',
          formatter: props.tooltipFormatter,
        },
        legend: {
          icon: 'roundRect',
          itemHeight: 2, // 粗细
          itemWidth: 16,
          itemGap: 56,
          ...(props.legend as any),
        },
        grid: {
          left: '30',
          right: '40',
          bottom: '0',
          containLabel: true,
          ...(props.grid as any),
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: props.x,
          show: props.showxAxis,
        },
        yAxis: {
          type: 'value',
          minInterval: props.onlyInteger ? 1 : 0,
          splitLine: {
            //网格线
            lineStyle: {
              type: 'dashed', //设置网格线类型
            },
            show: true, //隐藏或显示
          },
        },
        series,
      });

      nextTick(() => {
        window.addEventListener('resize', resizeCharts);
      });
    };

    //* charts 自适应屏幕变化
    function resizeCharts() {
      chartsInstance.resize();
    }

    function getInstance() {
      return chartsInstance;
    }

    onMounted(() => {
      initCharts();
    });

    onUnmounted(() => {
      window.removeEventListener('resize', resizeCharts);
    });

    return {
      chartsWrap,
      resizeCharts,
      initCharts,
      getInstance,
    };
  },
});
